@import "fonts";
#main, #right{
  ul {
    list-style: none;
    padding:0;
    margin: 0;

    li {
      a {
        //width: 100%;
        position: relative;
        display: block;


        &:before {
          font-family: "fontello";
          font-style: normal;
          font-weight: normal;
          speak: none;
          display: inline-block;
          text-decoration: inherit;
          width: 1em;
          margin-right: .2em;
          text-align: center;
          font-variant: normal;
          text-transform: none;
          line-height: 1em;
          margin-left: .2em;
        }
      }

      &.ext-pdf a:before {
        content: '\f1c1';
        color: $red;
      }

    }
  }
}
#main{

  .ce_downloads{
    h2{
      margin: 30px 0 8px;
    }
    ul {
      border-top: 1px solid $grey-light;

      li {
        padding: 1em 0;
        //white-space: nowrap;
        border-bottom: 1px solid $grey-light;
        position: relative;

        img {
          margin-right: 10px;
          margin-bottom: -4px
        }

        a {
          padding-right: 100px;

        }

        span.size {
          font-size:.7em;
          position: absolute;
          right: 0;
        }
      }
    }
  }
}

#right {
  .ce_downloads{
    ul {

      li {
        a{
          //padding-left: 2em;
          padding: .4em 0;
        }
        span.size {
          display: none;
        }
      }
    }
  }
}
