/* ---- mobile navigation ----------- */
.mcloud{
  display: none;
  position: absolute;
  right:5px;
  top:30px;
  background-color: lighten($white, 20%);
  border-radius: 5px;
  //border: 1px solid $white;
  padding:.5em 1em .5em .5em;
}
#mn_button{
  text-decoration: none;
  font-size:19px;
  color:$black;
  line-height: 30px;
  vertical-align: middle;

}
#mn_button.open:before{
  content: '☰';
  padding: 0 10px;
  display: inline;
}
#mn_button.close:before{
  content: 'x';
  padding: 0 10px;
  display: inline;
}
#overlap{
  background-color: rgba(255,255,255,0.7) !important;
  position: absolute;
  top:0;
  right: 0;
  left:0;
  bottom:0;
  /*z-index: 8888;*/
}
.mobile_slideout{
  position: absolute;
  width: 100%;
  /*top:90px;*/
  left:0;
  z-index: 9999;
  //background-color: $green-light;
  -webkit-box-shadow: -1px 36px 46px -28px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 36px 46px -28px rgba(0,0,0,0.75);
  box-shadow: -1px 36px 46px -28px rgba(0,0,0,0.75);
  display: none;
  padding-bottom:30px;
}
#header .mobile_slideout .searchform{
  position: relative;
  right:0;
  top: 0;
  padding:20px;
  //border-bottom: 1px solid $green-light;
}

#header .mobile_slideout .searchform form{
  padding: 10px;
  margin:0;
  //border: 1px solid $green-light;
}
#header .mobile_slideout .searchform input {
  background: none repeat scroll 0 0 transparent;
  border: none;
  border-radius: 2px;
  margin: 0;
  padding: 3px;
  transition: width 0.2s ease-out 0s;
  width: 80%;
}

#header .mobile_slideout .searchform input:focus{
  /*width: 250px;*/
}

#header .mobile_slideout .searchform .form-submit{
  background: url('../img/search_btn.png') no-repeat 0px 2px transparent;
  border:none;
  float:right;
  height: 28px;
  width: 28px;
}

.mobile_slideout #mainnavi{
  /*display: none;*/
  position: relative;
  z-index: 1001;
}
.mobile_slideout #mainnavi{
  /*border-bottom: 1px solid #c6c6c6;*/
}
.mobile_slideout #mainnavi ul{
  list-style: none;
  padding: 0;
  margin:0 0;

}
.mobile_slideout #mainnavi li{
  padding: 0;
  font-size: 19px;
  line-height: 54px;
}
.mobile_slideout #mainnavi a,
.mobile_slideout #mainnavi span{
  text-decoration: none;
  color: $black;
  display: block;
  cursor:pointer;
  padding-left:20px;
}
.mobile_slideout ul.level_1 > li{
  border-bottom: 1px solid $white;
}
.mobile_slideout ul.level_1 li.submenu a.submenu,
.mobile_slideout ul.level_1 li.submenu span.active.submenu{
  font-size: 19px;
  line-height: 54px;
  padding-left: 20px;
}
.mobile_slideout ul.level_1 li.submenu a.submenu:after,
.mobile_slideout ul.level_1 li.submenu span.submenu:after{
  content: "";
  font: 14px/1 FontAwesome;
  color: $black;
  float: right;
  font-size: 22px;
  line-height: 54px;
  margin: 0 18px 0 0;
  transition: transform 400ms ease-in 0s;
}
.mobile_slideout ul.level_1 li.submenu.open a.submenu:after,
.mobile_slideout ul.level_1 li.submenu.open span.submenu:after
{
  transform: rotate(90deg);
  color: $white;
}
.mobile_slideout #mainnavi ul.level_1 li.open,
.mobile_slideout #mainnavi ul.level_1 li.trail,
.mobile_slideout #mainnavi ul.level_1 li.active,
.mobile_slideout #mainnavi ul.level_1 li:hover,
.mobile_slideout #mainnavi ul.level_1 li:focus{
  background-color: darken($white,10%);
}

.mobile_slideout #mainnavi ul.level_1 li.submenu.active span,
.mobile_slideout #mainnavi ul.level_1 li.submenu.active span.submenu:after,
.mobile_slideout #mainnavi ul.level_1 li.submenu:hover a.submenu:after,
.mobile_slideout #mainnavi ul.level_1 li.open a,
.mobile_slideout #mainnavi ul.level_1 li.trail a,
.mobile_slideout #mainnavi ul.level_1 li:hover a,
.mobile_slideout #mainnavi ul.level_1 li:focus a{
  color: $grey-middle;
}
.mobile_slideout #mainnavi ul.level_2{
  background-color: $white;
  color: $black;
  padding: 30px 0;
}
.mobile_slideout #mainnavi ul.level_2 li{
  /*background: none;*/
}
.mobile_slideout #mainnavi ul.level_1 li.submenu ul.level_2 li span,
.mobile_slideout #mainnavi ul.level_1 li.submenu ul.level_2 li a{
  color: $black !important;
  font-size: 17px;
  line-height: 1.3rem;
  padding: 10px 20px 10px 34px;
}
.mobile_slideout #mainnavi ul.level_2{
  /*display:none;*/
}
.mobile_slideout #mainnavi li.active ul.level_2,
.mobile_slideout #mainnavi li.trail ul.level_2{
  display: block;
}

.mobile_slideout #metanavi{
  font-size: 0.9rem;
}
#header .mobile_slideout .topmenu{
  font-size: 18px;
}
#header .mobile_slideout .topmenu ul{
  list-style: none;
  padding: 0;
  margin:0 0;
}
#header .mobile_slideout .topmenu li{
  padding: 0;
  float:none;
}
#header .mobile_slideout .topmenu a,
#header .mobile_slideout .topmenu span{
  text-decoration: none;
  color: $black;
  display: block;
  cursor:pointer;
  line-height: 54px;
  padding-left: 20px;
}
#header .mobile_slideout .topmenu li:hover,
#header .mobile_slideout .topmenu li:focus,
#header .mobile_slideout .topmenu li.active {
  background-color: darken($white,10%);
}
#header .mobile_slideout .topmenu a:hover,
#header .mobile_slideout .topmenu a.trail,
#header .mobile_slideout .topmenu span.active{
  color: $white;
}
/* mobile-Elemente*/
.show_mobile, .show_tablet{
  display: none;
}
.no_mobile, .no_tablet, .show_desktop {
  display: block;
}

#header .menustrip{
  display:none;
}
#header.show .menu-toggler {
  position: fixed;
}
#header .menu-toggler.opened {
  /*height: 40px;*/
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}
#header .menu-toggler .bar {
  width: 30px;
  height: 6px;
  background-color: #b1b2b3;
  margin-bottom: 3px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

#header .menu-toggler.opened .bar.middle {
  opacity: 0;
}
#header .menu-toggler.opened .bar.first {
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-top: 10px;
}
#header .menu-toggler.opened .bar.last {
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin-top: -18px;
}
.ie8 #header .menu-toggler.opened .bar.last,
.ie8 #header .menu-toggler.opened .bar.middle,
.ie8 #header .menu-toggler.opened .bar.first{
  display: none;
}
.ie8 #header .menu-toggler.opened{
  width: 50px;
  height: 50px;
  top:0;
  right:100px;
  background: url('../img/header_close_gray.png') no-repeat 50% 50% transparent;
}
@media only screen and (max-width: 920px) {
  .mcloud{ display:block; }
}
@media only screen and (max-width: 368px) {
  .mcloud {
    top: 15px;
  }

  #mn_button {
    text-decoration: none;
    font-size: 0.9em;
    color: $black;
    line-height: 30px;
    vertical-align: middle;
    content: '';
    width: 1.5em;
    display: block;
    overflow: hidden;
  }
}