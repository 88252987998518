#breadcrumb-wrapper{
  .inside{
    @include make-container();
    @include make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints);
  }
  .mod_breadcrumb {
    ul{
      //float:right;
      display: flex;
      flex-wrap: wrap;
      padding: 14px 0 0 0;
      margin: 0 0 10px 0;
      list-style: none;
      font-size:.85rem;
      color: $breadcrumb-active-color;
      strong{
        font-weight:400;
      }
      li {
        // The separator between breadcrumbs (by default, a forward-slash: "/")
        padding-left: 10px;

        &::before {
          display: inline-block; // Suppress underlining of the separator in modern browsers
          padding-right:8px;
          //color: ;
          content: '›';
          //font-size: 15px;
          position: relative;
          top: 0;
        }
        &.first {
          padding-left: 0;
        }
        &.first::before {
          display: none;
          content:'';
        }
        a{
          color: $gray-900;
        }
        a:hover, a:focus{
          color: $blue;
          text-decoration: underline $blue;
        }
        &.active {
          color: $breadcrumb-active-color;
        }

      }

      // IE9-11 hack to properly handle hyperlink underlines for breadcrumbs built
      // without `<ul>`s. The `::before` pseudo-element generates an element
      // *within* the .breadcrumb-item and thereby inherits the `text-decoration`.
      //
      // To trick IE into suppressing the underline, we give the pseudo-element an
      // underline and then immediately remove it.
      + .breadcrumb-item:hover::before {
        text-decoration: underline;
      }
      // stylelint-disable-next-line no-duplicate-selectors
      + .breadcrumb-item:hover::before {
        text-decoration: none;
      }
    }

  }

}