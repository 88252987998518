.desktop-nav-container{
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }

  .main-navigation {
    position: relative;
    justify-content: flex-end;
    color: $grey-dark;
    margin-top: -30px;
    display: flex;
    .nav{
      margin-top: 0;
    }
    .nav-item{
      position: relative;
    }
    .nav-link{
      color: $grey-dark;
    }

    .nav-link:after{
      content:'';
      display: block;
      position: absolute;
      left:0;
      right:0;
      bottom:0;
      border-radius: 3px;
      background-color:transparent;
    }

    .nav-link{
      font-size: 1em;
      padding: 0 18px 12px;
      border:none;
    }
    .nav-link:last-child
    {
      margin-right: 0;
      padding-right: 0;
    }

    .nav-item:hover .nav-link:after,
    .nav-item .nav-link:focus:after,
    .nav-item:active .nav-link:active:after,
    .nav-item.active .nav-link:after{
      bottom: -6px;
      height:6px;
      left:15px;
      background-color: $yellow;
      border-radius: 3px;
    }
    .nav-link:first-child:after{
      left: 0;
    }

    //aktiver zustand
    .nav-item.trail > a.nav-link,
    .nav-item.active strong.nav-link{
      color:$red;
      font-weight: 400;
    }

  }
}
$mobile-container-padding:30px;

.mobile-nav-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 95px;
  background-color: $white;
  box-shadow: 0 15px 10px -15px #111;
  z-index: 100;
  padding: 20px;
  display: block;
  height: auto;
  transition: .6s height ease-in-out;

  @include media-breakpoint-up(md) {
    display: none;
    height:0;
  }
  .mod_search{
    margin-bottom: 20px;
  }
  .main-navigation{
    .nav,
    .dropdown-menu{
      display: flex;
      flex-direction: column;

      .nav-item{
        background-color: $white;
        border-bottom:1px solid rgba($blue,0.2);

        &:hover,&:focus,&.active{
          background-color: $gray-100;
        }
      }

      .nav-link,
      .dropdown-item{
        @include make-container();
        @include make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints);
        padding: 0 $mobile-container-padding;
        min-height: 42px;
        line-height: 42px;
        display: block;
      }
      .nav-link{
        font-weight: 600;
      }
      .nav-subitem{
        background-color: $gray-100;
        border-top:1px solid rgba($blue,0.2);
      }

    }

  }
}
aside{
  .mod_navigation {
    position: relative;
    display: block;

    ul.level_1 {
      margin-bottom: 0;
      padding-bottom: 0;
      padding-left: 13px;
      list-style-image: url("../../img/arrow.png");

      &.level_2,
      &.level_3,
      &.level_4 {
        border: 0;
      }
    }

    li{
      a, strong {
        width: 100%;
        position: relative;
        display: inline-block;
        margin: 0;
        padding: .5em 0 .5em 13px;
        border-top: 1px solid $grey-light;
        background-color: transparent;
        transition: .2s background-color ease-in-out;
        font-size: .9em;
      }
      a:hover,a:focus{
        background-color: $gray-100;
        text-decoration: none;
      }
      strong ,a.trail{
        background-color: $gray-700;
        color: $white;
      }

      &:last-child a,
      &:last-child strong{
        border-bottom: 1px solid $grey-light;
      }
    }
  }
}
#footer{
  .mod_navigation {
    position: relative;
    display: block;
    margin: 0;

    ul {
      margin: 12.8px 0;
      padding:0;
      li {
        position: relative;
        display: block;

        a ,strong{
          position: relative;
          display: block;
          margin-bottom: .4em;
          padding: 3px 5px;
          text-decoration: none;
          color: $white;
          font-weight: 400;

          &:hover, &.forward,&.active{
            color: $red;
            background-color: $white;
          }
          &:before {
            font-family: 'fontello';
            content: '\f105';
            speak: none;
            font-size: 1em;
            margin-right: .3em;

          }
        }
      }
    }
  }
}

.menu-toggler{
  width: 40px;
  height: 40px;
  margin-top: 15px;
  position: relative;
  display: block;
  float:right;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  overflow:hidden;

  @include media-breakpoint-up(md) {
    display: none;
  }

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 50%;
    background: $blue;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  span:nth-child(even) {
    left: 50%;
    border-radius: 0 2px 2px 0;
  }

  span:nth-child(odd) {
    left:0;
    border-radius: 2px 0 0 2px;
  }
  span:nth-child(1),
  span:nth-child(2) {
    top: 4px;
  }
  span:nth-child(3),
  span:nth-child(4) {
    top: 11px;
  }
  span:nth-child(5),
  span:nth-child(6) {
    top: 18px;
  }

  &.open span:nth-child(1),
  &.open span:nth-child(6) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &.open span:nth-child(2),
  &.open span:nth-child(5) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  &.open span:nth-child(1) {
    left: 1px;
    top: 4px;
  }
  &.open span:nth-child(2) {
    left: calc(50% - 8px);
    top: 4px;
  }
  &.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }
  &.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }
  &.open span:nth-child(5) {
    left: 1px;
    top: 15px;
  }
  &.open span:nth-child(6) {
    left: calc(50% - 8px);
    top: 15px;
  }
}
