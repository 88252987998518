#coverimage-wrapper{
  background-color:$gray-700;

  figure {
    position: relative;
    overflow: hidden;
    text-align: center;
    clear: both;
    display: block;
    margin:0;
    padding: 0;

    img{
      display: block;
      width:100%;
      height:auto;
      margin:0;
    }
    map {
      width: auto;
      max-width:none;
      bottom: 1px;
      left: 0;
      top: 0;
      bottom:0;
      position: absolute;
      padding: 0 2em;
      background-color: rgba(0, 0, 0, .8);
      line-height: 20px;
      color: #ccc;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-up(sm) {
        max-width: 220px;
      }

      h1 {
        margin-top: .7em;
        font: 1.5em Shojumaru;
        color: $white;
      }
    }

  }

}
