.feature_teaser{
  padding: 20px;
  margin-bottom: 40px;
  box-shadow: 0 0 10px $gray-300;

  h3{
    font-size: 1.1em;
    padding-bottom: 1em;
  }
  .icon_wrap{

    .icon{
      font-size: 3em;
      border: 2px solid #000;
      border-radius: 50%;
      padding:10px;
    }
    padding-bottom: 30px;
  }
  .teaser{
  }

  &.red .icon{
    color:$red;
    border-color: $red;
  }
  &.blue .icon{
    color:$blue;
    border-color: $blue;
  }
  &.yellow .icon{
    color:$yellow;
    border-color: $yellow;
  }

}
#right{
  .tm_teaser{
    padding: 30px 0;
    border-bottom: 1px solid $grey-light;
    &:first-child{
      padding-top:0;
    }
    &:last-child{
      border-bottom: none;
    }
  }
}