

.mod_search {
  margin-bottom: 40px;

  fieldset{
    margin:0;
    padding:0;
  }
  .header {
    font-size: .8em;
    color:$gray-650;
    padding-top: 20px;
    border-top: 1px solid $gray-200;
  }

  .radio_container {
    border:none;

    label {
      padding-top: 3px;
    }
  }
  h3 .relevance{
    font-size: .6em;
    color: $gray-700;
  }
  .context .highlight {
    background-color: #ffe100;
  }
}
