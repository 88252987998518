.ce_comments {
  position: relative;
  display: block;
  margin-top: 30px
}
.ce_comments label {
  position: relative;
  display: inline-block
}
.ce_comments span.captcha_text {
  display: inline-block !important
}
.ce_comments span.captcha_text,
.ce_comments .textarea {
  margin-bottom: 0 !important
}
.ce_comments label.invisible {
  width: 100%;
  height: 20px;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: inline-block
}
.ce_comments .widget {
  margin-bottom: 20px
}
.ce_comments .form {
  position: relative;
  display: block;
  margin-top: 40px
}
.ce_comments input {
  margin: 0px !important
}
.ce_comments h2 {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid $grey-light
}
