.ce_accordion {
  margin-bottom: 1px
}
.ce_accordion .toggler {
  padding: 4px 4px 4px 10px;
  background: $grey-light url("../../img/accordion_bg.png") right center no-repeat;
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;
  cursor: pointer;
  outline: none !important
}
.ce_accordion .toggler:hover {
  background-color: #e3e3e3
}
.ce_accordion .ui-accordion-header-active {
  background-image: url("../../img/accordion_bg_active.png");
  background-repeat: no-repeat
}
.ce_accordion .accordion {
  padding: 30px;
  background-color: #fafafa
}