/* shojumaru-regular - latin */
@font-face {
  font-family: 'Shojumaru';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/shojumaru-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Shojumaru'), local('Shojumaru-Regular'),
  url('../../fonts/shojumaru-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/shojumaru-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/shojumaru-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/shojumaru-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/shojumaru-v5-latin-regular.svg#Shojumaru') format('svg'); /* Legacy iOS */
}
/* artifika-regular - latin */
@font-face {
  font-family: 'Artifika';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/artifika-v8-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Artifika Regular'), local('Artifika-Regular'),
  url('../../fonts/artifika-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/artifika-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/artifika-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/artifika-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/artifika-v8-latin-regular.svg#Artifika') format('svg'); /* Legacy iOS */
}
/* asap-regular - latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/asap-v8-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Asap Regular'), local('Asap-Regular'),
  url('../../fonts/asap-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/asap-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/asap-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/asap-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/asap-v8-latin-regular.svg#Asap') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'fontello';
  src: url('../../fonts/fontello.eot?65633019');
  src: url('../../fonts/fontello.eot?65633019#iefix') format('embedded-opentype'),
  url('../../fonts/fontello.woff2?65633019') format('woff2'),
  url('../../fonts/fontello.woff?65633019') format('woff'),
  url('../../fonts/fontello.ttf?65633019') format('truetype'),
  url('../../fonts/fontello.svg?65633019#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-users:before { content: '\e800'; } /* '' */
.icon-info-circled:before { content: '\e801'; } /* '' */
.icon-home:before { content: '\e802'; } /* '' */
.icon-dot:before { content: '\e803'; } /* '' */
.icon-cog:before { content: '\e804'; } /* '' */
.icon-cog-alt:before { content: '\e805'; } /* '' */
.icon-wrench:before { content: '\e806'; } /* '' */
.icon-left-open:before { content: '\e807'; } /* '' */
.icon-right-open:before { content: '\e808'; } /* '' */
.icon-up-open:before { content: '\e809'; } /* '' */
.icon-down-open:before { content: '\e80a'; } /* '' */
.icon-down-big:before { content: '\e80b'; } /* '' */
.icon-left-big:before { content: '\e80c'; } /* '' */
.icon-campsite:before { content: '\e80d'; } /* '' */
.icon-right-big:before { content: '\e80e'; } /* '' */
.icon-up-big:before { content: '\e80f'; } /* '' */
.icon-college:before { content: '\e810'; } /* '' */
.icon-award:before { content: '\e811'; } /* '' */
.icon-download:before { content: '\e812'; } /* '' */
.icon-upload:before { content: '\e813'; } /* '' */
.icon-lock:before { content: '\e814'; } /* '' */
.icon-export:before { content: '\e815'; } /* '' */
.icon-attention:before { content: '\e816'; } /* '' */
.icon-location:before { content: '\e817'; } /* '' */
.icon-pin:before { content: '\e818'; } /* '' */
.icon-pitch:before { content: '\e82a'; } /* '' */
.icon-school:before { content: '\e834'; } /* '' */
.icon-link-ext:before { content: '\f08e'; } /* '' */
.icon-left-circled:before { content: '\f0a8'; } /* '' */
.icon-right-circled:before { content: '\f0a9'; } /* '' */
.icon-up-circled:before { content: '\f0aa'; } /* '' */
.icon-down-circled:before { content: '\f0ab'; } /* '' */
.icon-filter:before { content: '\f0b0'; } /* '' */
.icon-sort:before { content: '\f0dc'; } /* '' */
.icon-sort-down:before { content: '\f0dd'; } /* '' */
.icon-sort-up:before { content: '\f0de'; } /* '' */
.icon-comment-empty:before { content: '\f0e5'; } /* '' */
.icon-chat-empty:before { content: '\f0e6'; } /* '' */
.icon-lightbulb:before { content: '\f0eb'; } /* '' */
.icon-doc-text:before { content: '\f0f6'; } /* '' */
.icon-angle-double-left:before { content: '\f100'; } /* '' */
.icon-angle-double-right:before { content: '\f101'; } /* '' */
.icon-angle-double-up:before { content: '\f102'; } /* '' */
.icon-angle-double-down:before { content: '\f103'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-laptop:before { content: '\f109'; } /* '' */
.icon-circle-empty:before { content: '\f10c'; } /* '' */
.icon-quote-left:before { content: '\f10d'; } /* '' */
.icon-quote-right:before { content: '\f10e'; } /* '' */
.icon-circle:before { content: '\f111'; } /* '' */
.icon-smile:before { content: '\f118'; } /* '' */
.icon-star-half-alt:before { content: '\f123'; } /* '' */
.icon-help:before { content: '\f128'; } /* '' */
.icon-puzzle:before { content: '\f12e'; } /* '' */
.icon-angle-circled-left:before { content: '\f137'; } /* '' */
.icon-angle-circled-right:before { content: '\f138'; } /* '' */
.icon-angle-circled-up:before { content: '\f139'; } /* '' */
.icon-angle-circled-down:before { content: '\f13a'; } /* '' */
.icon-export-alt:before { content: '\f14d'; } /* '' */
.icon-slack:before { content: '\f198'; } /* '' */
.icon-file-pdf:before { content: '\f1c1'; } /* '' */
.icon-file-word:before { content: '\f1c2'; } /* '' */
.icon-file-excel:before { content: '\f1c3'; } /* '' */
.icon-file-powerpoint:before { content: '\f1c4'; } /* '' */
.icon-file-image:before { content: '\f1c5'; } /* '' */
.icon-file-archive:before { content: '\f1c6'; } /* '' */
.icon-file-audio:before { content: '\f1c7'; } /* '' */
.icon-file-video:before { content: '\f1c8'; } /* '' */
.icon-file-code:before { content: '\f1c9'; } /* '' */
.icon-paper-plane-empty:before { content: '\f1d9'; } /* '' */
.icon-tty:before { content: '\f1e4'; } /* '' */
.icon-plug:before { content: '\f1e6'; } /* '' */
.icon-venus:before { content: '\f221'; } /* '' */
.icon-mars:before { content: '\f222'; } /* '' */
.icon-mercury:before { content: '\f223'; } /* '' */
.icon-transgender:before { content: '\f224'; } /* '' */
.icon-bed:before { content: '\f236'; } /* '' */
.icon-balance-scale:before { content: '\f24e'; } /* '' */
.icon-contao:before { content: '\f26d'; } /* '' */
.icon-map-signs:before { content: '\f277'; } /* '' */
.icon-envira:before { content: '\f299'; } /* '' */
.icon-volume-control-phone:before { content: '\f2a0'; } /* '' */
.icon-address-card-o:before { content: '\f2bc'; } /* '' */
.icon-user-circle:before { content: '\f2bd'; } /* '' */
.icon-user-circle-o:before { content: '\f2be'; } /* '' */
.icon-telegram:before { content: '\f2c6'; } /* '' */