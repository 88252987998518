body {
  padding: 0;
  margin: 0;
  font-family: $font-family-sans-serif;
  line-height: $line-height-base;
  //background: $grey-middle url("../../img/body-bg.png") center top no-repeat;
  color: $gray-700;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  //background-attachment: fixed
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3em;
  color: $grey-dark;
  font-family: $headings-font-family;
}
a {
  text-decoration: none;
  color:darken($red,10%);
  outline: none;
}
a:hover {
  text-decoration: underline;
  color: $blue;
}
blockquote {
  padding-left: 70px;
  background-image: url("../../img/blockquote.png");
  background-position: left top;
  background-repeat: no-repeat;
  color: $gray-600;
}
p.warning {
  padding: 20px 20px 20px 50px;
  background: $danger url("../../img/format_icon.png") left center no-repeat;
  border: 1px solid $danger;
  font-weight: bold;
  color: $white;
}
p.confirmation {
  padding: 20px 20px 20px 50px;
  background: $success url("../../img/format_icon.png") left center no-repeat;
  font-weight: bold;
  color: $white;
}
p.information {
  padding: 20px 20px 20px 50px;
  background: $info url("../../img/format_icon.png") left center no-repeat;
  font-weight: bold;
  color: $white;
}
p a:after{
  font-family: 'fontello';
  content: '\f101';
  display: inline-block;
  font-size: 1em;
  padding-left: .3em;
  margin-right: .5em;
  speak: none;
}
p.back a:after{
  content:'';
  display:none;
}
p.back a:before{
  font-family: 'fontello';
  content: '\f100';
  display: inline-block;
  font-size: 1em;
  margin-right: .3em;
  speak: none;
}
.mod_article>* {
  margin-right: 0;
  margin-left: 0;
}
table {
  margin-bottom: 0;
}
figure.image_container,
figure.thumbnail{
  margin:0;
  padding:0;
  img{
    max-width: 100%;
    height: auto;
    display: block;
    margin:15px 0;
  }
}
.mod_article *[class*="ce_"],
.mod_article *[class*="mod_"],
.mod_article *[class*="layout_"]>*,
.mod_article *[class*="event_"]>* {
  margin-right: 0;
  margin-left: 0
}
.invisible{
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.line {
  width: 100%;
  height: 1px;
  position: relative;
  display: inline-block;
  margin: 30px 0;
  border-top: 1px solid $grey-light;
}
.clear {
  position: relative;
  clear: both;
  display: block
}

.back-to-top {
  position: fixed;
  bottom:20px;
  right:20px;
  background: rgba($blue, 0.7);
  color:$yellow;
  border: 3px solid $yellow;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  transition: .3s all ease-in-out;
  border-radius: 50%;

  &:before {
    width: auto;
    font-size: 20px;
    content: "\e809";
    display: inline-block;
    font-family: 'fontello';
    speak: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 0;
    left: 7px;

  }

  &:hover{
    background: rgba($blue, 1);
    border-color: $white;
    color:$white;
  }
}

@include media-breakpoint-down(sm) {
  .mobile-hide {
    display: none;
  }
}

#wrapper {
  //padding-right: 10px;
  //padding-left: 10px;
  background-color: $white;
  //-webkit-box-shadow: 0 0 3px 3px rgba(0, 0, 0, .2);
  //box-shadow: 0 0 3px 3px rgba(0, 0, 0, .2);
  //border: 30px solid $white;
  //border-radius:5px;
  //behavior: url("assets/css3pie/1.0.0/PIE.htc");
}
.subsite #container {
  @include make-container();
  @include make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints);
  margin-top: 30px;
  margin-bottom: 80px;
}
.background-gray{
  background-color: $gray-100;
}
.article-row{
  padding: 40px 0;
}
.trauer{
  border: 5px solid $black;
  padding: 20px;
  padding-right: 40px;
  margin: 20px;
  position:relative;
  overflow:hidden;
  box-shadow: 0 0 20px #999;
}
.trauerschleife{
  position: absolute;
  height: 100px;
  width: 100px;
  background: url('../../img/trauerband.png') no-repeat 0 0;
  background-size: cover;
  left:0;
  top:0;
  transform: rotate(-90deg);

  @include media-breakpoint-down(md) {
    display: none;
  }
}