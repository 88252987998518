

.mod_faqlist {
  ul {
    margin-top: 20px;
    border-top: 1px solid $grey-light;

    li{
      margin:0 !important;
      padding: 0 !important;

      a {
        position: relative;
        display: block;
        border-bottom: 1px solid $gray-400;
        padding: .7em 2em .7em .5em;

        &:hover {
          background-color: $gray-100;
          text-decoration: none
        }
        &:after{
          speak: none;
          font-family: 'fontello';
          content: '\f0a9';
          font-size: 1em;
          position: absolute;
          right: .5em;
          //float:right;
        }
      }
    }
  }
}
.mod_faqreader{
  .info{
    font-size: .8em;
    color: $gray-500;
  }
}