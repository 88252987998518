#main{
  .mod_eventlist {
    .header {
      padding: 6px 10px;
      background-color: $gray-700;
      font-weight: 400;
      font-size: .86em;
      color: $white;
    }

    .event {
      position: relative;
      padding: 30px 0;
      border-bottom: 1px solid $grey-light;

      h3 {
        margin-top: 0 !important;
        font-size: 1.05em;
        margin-right: 100px;
        //margin: 20px 0 3px;
      }

      p:last-child{
        margin-bottom: 0;
      }
      .time{
        position: absolute;
        right: 0;
        top:30px;
        font-size: .8em;
        margin: 3px 0;
      }
      .more{
        margin:0;
        padding:0;
      }
    }
    .last {
      border-bottom: 0;
    }
  }
}