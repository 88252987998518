#footer {
  background-color: $gray-700;
  color: $white;
  font-size: .85em;

  .inside {
    padding: 50px 0 80px;
  }

  img{
    max-width: 100%;
    height:auto;
  }

  h3 {
    margin-top: 0;
    margin-bottom: .5em;
    padding-top: 0;
    font-size: .9rem;
    font-weight: bold;
    color: $white;
  }

  .ce_text {
    width: 220px;
    position: relative;
    float: left;
    display: block;
    margin-right: 30px;
    margin-left: 0;
    //line-height: 20px;
  }

  .col{
    margin-bottom:40px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
}
