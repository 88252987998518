.pagination {
  padding-top: 30px;
  font-size: .8em;
  border-top: 1px solid $grey-light;

  p {
    position: relative;
    float: left;
    margin:0;
    padding:0;
    display: inline;
  }

  ul {
    list-style: none;
    position: relative;
    float: right;
    display: inline;
    margin-left: 20px;

    li {
      float: left;
      margin-right: 10px;
      padding-left: 10px;
      border-left: 1px solid $grey-light;
    }
  }
}

