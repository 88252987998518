html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
@import "bootstrap/mixins";
@import "bootstrap/functions";
@import 'parameters';
@import "fonts";
@import "bootstrap/forms";
@import "bootstrap/input-group";
@import "bootstrap/custom-forms";

@import "bootstrap/grid";
@import "bootstrap/button-group";
@import "bootstrap/buttons";
//@import "bootstrap/breadcrumb";
@import "bootstrap/transitions";
@import "bootstrap/utilities";
@import "bootstrap/nav";

@import 'page';
@import 'aside';
@import 'breadcrumb';
@import "header";
@import "main";
@import "footer";
@import 'navigation';
@import 'mobilemenu';
@import 'calendar';
@import 'events';
@import 'comments';
@import 'news';
@import 'pagination';
@import 'aside';
@import 'search';
@import 'coverpicture';
@import 'downloads';
@import 'faq';
@import 'accordion';
@import 'sitemap';
@import 'teaser';

