.mod_sitemap ul {
  margin-bottom: 0;
  padding-bottom: 0
}
.mod_sitemap ul.level_2,
.mod_sitemap ul.level_3,
.mod_sitemap ul.level_4,
.mod_sitemap ul.level_5 {
  padding-left: 14px
}
.mod_sitemap ul.level_1 a {
  font-weight: bold
}
.mod_sitemap ul.level_2 a {
  background-color: $white;
  font-weight: normal
}
.mod_sitemap ul li a {
  width: 100%;
  position: relative;
  display: inline-block;
  padding-top: 6px;
  padding-bottom: 4px;
  padding-left: 14px;
  background-image: url("../img/sitemap_a_list_icon.png");
  background-position: left center;
  background-repeat: no-repeat;
  border-top: 1px solid $grey-light;
  list-style-type: circle
}
.mod_sitemap ul li a:hover {
  background-color: #fafafa;
  text-decoration: none
}
