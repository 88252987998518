table.minicalendar{
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
  //margin-top:30px;

  thead {
    background-color: $grey-dark;
    color: $gray-300;

    .current {
      padding-top: 4px;
      padding-bottom: 4px;
      color: $white;
    }
    a {
      width: 100%;
      display: inline-block;
      padding-top: 4px;
      padding-bottom: 4px;
      background-color: $grey-middle;
      color: $white;
      &:hover {
        background-color: lighten($grey-middle,10%);
        text-decoration: none
      }
    }
    .label {
      padding-top: 2px;
      padding-bottom: 2px;
      font-weight: normal;
    }
    td, th {
      text-align: center;
    }
  }

  .days {
    padding-top: 4px;
    padding-bottom: 4px;
    text-align: center;
    background-color: #e7e7e7;
    background: linear-gradient(to bottom, #eeeeee 20%, #dedede 100%);
    -webkit-box-shadow: 0 0 $white;
    box-shadow: 0 0 $white;
    border-top: 1px solid $grey-light;
    border-right: 1px solid $grey-light;
    border-bottom: 0px solid $grey-light;
    border-left: 1px solid $grey-light;

    &:hover {
      background: linear-gradient(to bottom, #f9f9f9 20%, #ececec 100%);

    }
  }

  td.col_first {
    border-top: 1px solid $grey-light;
    border-right: 1px solid $grey-light;
    border-bottom: 0px solid $grey-light;
    border-left: 0px solid $grey-light
  }

  .today {
    background: linear-gradient(to bottom, #f9f9f9 20%, #ececec 100%);
  }
}
aside .mod_calendar {
  margin: 0
}
.mod_calendar {
  width: 100%
}
table.calendar {
  width: 100%
}
.calendar thead,
.calendar thead tr {
  width: 100%
}
.calendar thead a {
  color: $white
}
.calendar thead .head {
  background-color: $grey-dark;
  color: $white
}
.calendar thead .current {
  padding-top: 4px;
  padding-bottom: 4px
}
.calendar thead a {
  width: 100%;
  display: inline-block;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #696969
}
.calendar thead a:hover {
  background-color: #787878;
  text-decoration: none
}
.calendar thead .label {
  padding-top: 2px;
  padding-bottom: 2px;
  background: -moz-linear-gradient(top, #f8f8f8 0%, #f1f1f1 100%);
  background: -webkit-linear-gradient(top, #f8f8f8 0%, #f1f1f1 100%);
  background: -o-linear-gradient(top, #f8f8f8 0%, #f1f1f1 100%);
  background: -ms-linear-gradient(top, #f8f8f8 0%, #f1f1f1 100%);
  background: linear-gradient(to bottom, #f8f8f8 0%, #f1f1f1 100%);
  -pie-background: linear-gradient(top, #f8f8f8 0%, #f1f1f1 100%);
  font-weight: normal;
}
.calendar td,
.calendar th {
  text-align: center
}
.calendar tbody {
  width: 100%
}
.calendar tbody tr {
  width: 100%
}
.calendar .days {
  width: 14%;
  height: 9em;
  overflow: hidden;
  text-align: center;
  background-color: $red;
  background: -moz-linear-gradient(top, #eeeeee 20%, #dedede 100%);
  background: -webkit-linear-gradient(top, #eeeeee 20%, #dedede 100%);
  background: -o-linear-gradient(top, #eeeeee 20%, #dedede 100%);
  background: -ms-linear-gradient(top, #eeeeee 20%, #dedede 100%);
  background: linear-gradient(to bottom, #eeeeee 20%, #dedede 100%);
  -pie-background: linear-gradient(top, #eeeeee 20%, #dedede 100%);
  -webkit-box-shadow: 0 0 $white;
  box-shadow: 0 0 $white;
  border-top: 1px solid $grey-light;
  border-right: 1px solid $grey-light;
  border-bottom: 0px solid $grey-light;
  border-left: 1px solid $grey-light;
}
.calendar .days:hover {
  background: -moz-linear-gradient(top, #f9f9f9 20%, #ececec 100%);
  background: -webkit-linear-gradient(top, #f9f9f9 20%, #ececec 100%);
  background: -o-linear-gradient(top, #f9f9f9 20%, #ececec 100%);
  background: -ms-linear-gradient(top, #f9f9f9 20%, #ececec 100%);
  background: linear-gradient(to bottom, #f9f9f9 20%, #ececec 100%);
  -pie-background: linear-gradient(top, #f9f9f9 20%, #ececec 100%);
}
.calendar .days .header {
  position: relative;
  text-align: center;
  background-color: #8c8c8c;
  color: $white
}
.calendar .event {
  height: 15px;
  position: relative;
  overflow: hidden;
  margin: 3px 4px;
  padding-bottom: 2px;
  text-align: left;
  border-bottom: 1px solid #b0b0b0;
  line-height: 15px
}
.calendar td.col_first {
  border-top: 1px solid $grey-light;
  border-right: 1px solid $grey-light;
  border-bottom: 0px solid $grey-light;
  border-left: 0px solid $grey-light
}
.calendar .today {
  background: -moz-linear-gradient(top, #f9f9f9 20%, #ececec 100%);
  background: -webkit-linear-gradient(top, #f9f9f9 20%, #ececec 100%);
  background: -o-linear-gradient(top, #f9f9f9 20%, #ececec 100%);
  background: -ms-linear-gradient(top, #f9f9f9 20%, #ececec 100%);
  background: linear-gradient(to bottom, #f9f9f9 20%, #ececec 100%);
  -pie-background: linear-gradient(top, #f9f9f9 20%, #ececec 100%);
}
