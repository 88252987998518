#header {
  padding-top: 10px;

 .inside {
    border-bottom: 6px solid $blue;
    height: 85px;

    @include media-breakpoint-up(md) {
      height: auto;
    }
  }

  .mod_logo {
    img{
      max-width: 100%;
      height: auto;
      &.desktop-logo{
        display: none;

        @include media-breakpoint-up(md) {
          display:inline;
        }
      }
      &.mobile-logo{
        display: inline;
        max-height: 70px;
        width: auto;

        @include media-breakpoint-up(md) {
          display:none;
        }
      }
    }

  }
  .hotline {
    justify-content: flex-end !important;
    display: flex;
    font-size: .7rem;

    @include media-breakpoint-up(md) {
      font-size: .8rem;
    }
  }


}
