
#main {
  .inside {
    padding-right: 50px;
    @include media-breakpoint-down(md) {
      padding-right: 0;
      margin-bottom: 50px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
  }
  h1 {
    padding-bottom: 19px;
    border-bottom: 1px solid $grey-light
  }
  ul{
    list-style: none;
    margin:0;
    padding:0;

    li{
      padding-top: .5em;
      padding-bottom: .5em;
    }
  }
  .ce_text{
    ul {
      padding-left: 16px;
      list-style-type: disc
    }
    ol {
      padding-left: 21px;
      list-style-type: decimal
    }
  }
}
.fullpage #main .inside{
  padding-right: 0;
}