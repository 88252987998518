.mod_newsmenu {
  margin-right: 0;
  margin-left: 0
}
.mod_newslist .layout_simple {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid $grey-light
}
.mod_newslist .layout_simple time {
  position: relative;
  float: none;
  clear: both;
  display: block
}
.mod_newslist .layout_simple a {
  font-size: 14px
}
.mod_newslist .layout_short,
.mod_newsarchive .layout_short {
  margin-bottom: 20px;
  border-bottom: 1px solid $grey-light
}
.mod_newslist .last,
.mod_newsarchive .last {
  border-bottom: 0px
}
.mod_newslist p.info,
.mod_newsarchive p.info {
  margin-bottom: 10px;
  color: $grey-dark
}
.mod_newslist p.more {
  margin-top: -11px;
  padding-bottom: 7px
}
.mod_newsarchive h1 {
  padding-bottom: 16px;
  border-bottom: 1px solid $grey-light
}
.mod_newslist .layout_latest h2 {
  font-size: 18px
}

.mod_newsreader .layout_full h1,
.mod_newsreader .layout_full h2,
.mod_newsreader .layout_full h3,
.mod_newsreader .layout_full h4,
.mod_newsreader .layout_full h5,
.mod_newsreader .layout_full h6 {
  margin-top: 10px
}
