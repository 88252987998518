aside{
  font-size: .9em;

  .inside h1,
  .inside h2,
  .inside h3 {
    margin-top: 0
  }
  .ce_text {
    margin: 0;
    padding: 0
  }

  .mod_search {
    input.text {
      width: 148px;
      position: relative;
      float: left;
      display: block;
      margin-right: 10px;
      margin-left: 0
    }

    .formbody {
      white-space: nowrap;

      .submit {
        float: left;
        padding: 3px 6px;
      }
    }
  }
}
